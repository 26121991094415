<template>
  <div class="page-content">
    <div class="menu">
      <b-form-group>
        <label for="filiale-input">Filiale</label>
        <b-form-select
          id="filiale-input"
          v-model="selectedFiliale"
          @change="hundleFilialeChange"
          :options="computedAvailableFiliales"
          value-field="id"
          text-field="name"
          :disabled="
            $route.name === 'Objectifs' ||
              !computedAvailableFiliales ||
              $route.name === 'Statistics'
          "
          required
        >
          <template v-if="!computedAvailableFiliales.length">
            <b-form-select-option :value="null" disabled>
              -- Aucune filiale disponible --
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group>
        <label for="deposit-input">Dépôt</label>
        <b-form-select
          id="deposit-input"
          :disabled="
            !computedAvailableDeposits ||
              !computedAvailableDeposits.length ||
              $route.name === 'Objectifs' ||
              !computedAvailableDeposits ||
              $route.name === 'Statistics'
          "
          v-model="selectedDeposit"
          :options="computedAvailableDeposits"
          @change="hundleChangeFilter"
          value-field="id"
          text-field="name"
          required
        >
        </b-form-select>
      </b-form-group>
      <b-form-group>
        <label for="week-input">Semaine</label>
        <b-form-select
          id="week-input"
          v-model="selectedWeek"
          :options="getAvailableWeeks"
          @change="hundleChangeFilter"
          value-field="id"
          text-field="name"
          required
          :disabled="
            ($route.name === 'Objectifs' && !getReportingObjectifs.loaded) ||
              $route.name === 'Statistics'
          "
        ></b-form-select>
        <div class="dates" v-if="computedSelectedWeek">
          {{ computedSelectedWeek.start }} -
          {{ computedSelectedWeek.end }}
        </div>
      </b-form-group>
      <div class="menu-links">
        <div class="menu-title">Stat. opérationnelles</div>
        <div class="menu-link">
          <router-link class="menu-item" to="/reporting/statistics">
            Stat. planning
          </router-link>
        </div>
        <div class="menu-link">
          <router-link class="menu-item" to="/reporting/terrain">
            Stat. terrain
          </router-link>
        </div>
        <div class="menu-title">Perf. globales</div>
        <div class="menu-link">
          <router-link class="menu-item" to="/reporting/objectifs">
            Objectifs filiales
          </router-link>
        </div>
      </div>
    </div>
    <div class="content">
      <router-view :selectedWeek="selectedWeek" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as moment from 'moment'
moment.locale('fr')

export default {
  name: 'Reporting',
  data() {
    return {
      selectedFiliale: null,
      selectedDeposit: null,
      selectedWeek: null
    }
  },
  methods: {
    ...mapActions([
      'fetchFiliales',
      'fetchDeposits',
      'fetchWeeks',
      'fetchReportingStats'
    ]),
    hundleChangeFilter() {
      this.fetchStats()
    },
    hundleFilialeChange() {
      this.selectedDeposit = null
      this.fetchDeposits(this.selectedFiliale)
      this.fetchStats()
    },
    fetchStats() {
      this.fetchReportingStats({
        filiale_id: this.selectedFiliale,
        depot_id: this.selectedDeposit,
        week_id: this.selectedWeek
      })
    }
  },
  computed: {
    ...mapGetters([
      'getAvailableFiliales',
      'getAvailableDeposits',
      'getAvailableWeeks',
      'getReportingProcessing',
      'getReportingObjectifs'
    ]),
    computedAvailableFiliales: function() {
      if (!this.getAvailableFiliales || !this.getAvailableFiliales.length) {
        return []
      }
      return [
        { id: 0, name: 'Tous les Filiales' },
        ...this.getAvailableFiliales
      ]
    },
    computedAvailableDeposits: function() {
      if (!this.getAvailableDeposits || !this.getAvailableDeposits.length) {
        return []
      }
      return [
        { id: null, name: 'Tous les dépôts' },
        ...this.getAvailableDeposits
      ]
    },
    computedSelectedWeek: function() {
      let week = null
      if (this.selectedWeek) {
        this.getAvailableWeeks.forEach(w => {
          if (this.selectedWeek === w.id) {
            week = {
              ...w,
              start: moment(w.first_day_of_week, 'YYYY-MM-DD').format(
                'DD/MM/YYYY'
              ),
              end: moment(w.last_day_of_week, 'YYYY-MM-DD').format('DD/MM/YYYY')
            }
          }
        })
      }
      return week
    }
  },
  async mounted() {
    // get filiales
    await this.fetchFiliales()
    if (this.getAvailableFiliales.length) {
      this.selectedFiliale = 0
    }

    await this.fetchDeposits(this.selectedFiliale)
    // select default deposit
    if (this.getAvailableDeposits.length) {
      this.selectedDeposit = null
    }

    // get current year
    const year = moment().year()
    // get weeks of current year
    await this.fetchWeeks(year)
    // select current week
    const currentWeek = moment().week()
    this.getAvailableWeeks.forEach(week => {
      if (week.week_nbr == currentWeek) {
        this.selectedWeek = week.id
      }
    })

    this.fetchStats()
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');
.page-content {
  font-family: 'Source Sans Pro', 'Arial Narrow', Arial, sans-serif;
  position: relative;
  padding: 16px;
  height: 100%;
  width: 100%;

  .menu {
    position: absolute;
    width: 250px;
    padding-right: 15px;
    .form-group {
      margin: 4px 10px 0px;
      font-weight: 600;
      font-size: 13px;
      text-transform: uppercase;
      color: #26292cad;
      label {
        margin-top: 6px;
        margin-bottom: 0;
      }
      .bv-no-focus-ring {
        width: 150px;
        .custom-select {
          color: #26292c;
          height: calc(1.5em + 0.75rem + 1px);
          font-size: 12px;
          font-weight: 600;
        }
      }
    }

    .dates {
      padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      font-size: 12px;
      font-weight: 600;
      color: #26292c;
    }

    .menu-links {
      padding: 0.375rem 1.75rem 0.375rem 0.75rem;
      font-weight: 600;
      font-size: 13px;
      .menu-title {
        margin: 5px 0;
        font-size: 13px;
        text-transform: uppercase;
        color: #26292cad;
      }
      .menu-link {
        font-size: 13px;
        padding-left: 6px;
        a.menu-item {
          color: #26292c;
          &.router-link-active,
          &:hover {
            color: #2dabe2;
            text-decoration: none;
          }
        }
      }
    }
  }
  .content {
    display: block;
    padding: 20px;
    height: 100%;
    margin-left: 250px;
    overflow: hidden;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    border-radius: 3px;
    box-sizing: border-box;
  }
}
</style>
